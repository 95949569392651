import {faCalendarDay} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

const EntryItem = ({title, subtitle, date, children, href}) => {
    return (
        <div className="entry col-12 mt-5">
            <div className="grid-inner">
                <div className="entry-title text-black">
                    {href ?
                    <a href={href}>
                        <h4>
                            <strong>
                                {title}
                            </strong>
                            {subtitle && <span className="h5 d-block">{subtitle}</span>}
                        </h4>
                    </a> :
                    <h4>
                        <strong>
                            {title}
                        </strong>
                        {subtitle && <span className="h5 d-block">{subtitle}</span>}
                    </h4>}
                </div>
                <div className="entry-meta">
                    <span>
                        <FontAwesomeIcon icon={faCalendarDay} />
                        <span className="px-2 border-end">
                            {date}
                        </span>
                    </span>
                </div>
                <div className="entry-content">
                    {children}
                    {href && <a href={href} className="more-link">Ver más...</a>}
                </div>
            </div>
        </div>
    );
}

export default EntryItem