import {Outlet} from "react-router-dom";
import Footer from "../../components/Footer";
import Topbar from "../../components/topbar/Topbar";

const Main = () => {
    return (
        <>
            <Topbar />
            <Outlet />
            <Footer />
        </>
    );
}

export default Main;