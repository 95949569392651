import {Nav} from "react-bootstrap";
import {Link, useLocation} from "react-router-dom";

const TopbarLink = ({path, children, target}) => {

    const { pathname } = useLocation();



    return (
        <Nav.Link as={Link} to={path} target={target} className={"ps-3 pe-5 " + (pathname.split("/")[1] === path.split("/")[1] && "bg-primary text-white")}>
            {children}
        </Nav.Link>
    )
}

export default TopbarLink;