import {DateTime} from "luxon";
import {useEffect, useState} from "react";
import EntryItem from "../../components/EntryItem";

const News = () => {

    const [canView, setCanView] = useState(true);

    // show after april 15th
    useEffect(() => {
        if (DateTime.local().year === 2022 && DateTime.local().month <= 4 && DateTime.local().day < 15) {
            setCanView(false);
        }
    }, [])

    return (
        <>
            <section id="content">
                <div className="content-wrap">
                    <div className="container clearfix">
                        <div className="position-relative">
                            {/* <div className="timeline-border"></div> */}
                            <div id="posts" className="post-grid grid-container row post-timeline col-mb-50 w-75 mx-auto"
                                data-basewidth=".entry:not(.entry-date-section):eq(0)">

                                <div className="entry entry-date-section col-12 mb-3"><span>MAYO 2024</span></div>

                                <EntryItem title="INFORME ACERCA DE LA EVOLUCIÓN DE LAS OBRAS" date="9 Mayo 2024">
                                    <p>
                                        Nos complace informar a los futuros propietarios de que el Ayuntamiento de Calviá, mostrando una excelente empatía hacia ustedes y nosotros mismos, ha tenido a bien permitirnos continuar con la ejecución de las obras durante la época estival a pesar de la estricta normativa para evitar la contaminación acústica vigente en el Municipio y bajo el compromiso, por nuestra parte, de procurar minimizar los ruidos que siempre provocan las obras y concentrarlos en las horas centrales del día para evitar las molestias a los residentes del entorno.
                                    </p>
                                    <p>
                                        Ello nos permitirá seguir avanzando en la evolución de las obras, las cuales, en fecha actual, tienen ya realizados los cerramientos perimetrales y forjados de la planta baja del edificio A1 y los cerramientos y forjados de la planta sanitaria y la planta baja del edificio A2.
                                    </p>
                                    <p>
                                        Les invitamos a visitar la zona donde podrán apreciar los avances realizados, estamos empeñando todo nuestro esfuerzo para que, cuanto antes puedan disfrutar de su nuevo hogar.
                                    </p>
                                </EntryItem>

                                <div className="entry entry-date-section col-12 mb-3"><span>ENERO 2024</span></div>


                                <EntryItem title="FIRMA DE LOS CONTRATOS DE COMPRAVENTA"
                                    date="11 Enero 2024">
                                    <p>
                                        Continuando con la información facilitada el pasado día 8, nos complace comunicar a nuestros estimados clientes
                                        que ya hemos recibido la autorización de la Consellería d’Habitatge para percibir cantidades a cuenta,
                                        la cual podrán visualizar accediendo al siguiente link:
                                    </p>
                                    <a href="\pdf\DGHA Autorizacion percibir cantidades a cuenta.pdf" target="_blank" className="mb-5">Autorización percibir cantidades a cuenta</a>

                                    <p className="mt-3">
                                        Estaremos en contacto por los medios habituales para la firma de los contratos que les convertirán en adjudicatarios definitivos.
                                    </p>
                                </EntryItem>

                                <EntryItem title="FIRMA DE LOS CONTRATOS DE COMPRAVENTA CON LOS ADJUDICATARIOS"
                                    date="8 Enero 2024">
                                    <p>
                                        Como informábamos a los adjudicatarios de las viviendas de la promoción en nuestra anterior noticia,
                                        en las próximas fechas, una vez recibida de la Consellería d’Habitatge la autorización para percibir
                                        cantidades a cuenta, les estaremos convocando para la firma del contrato de compraventa, en dicha
                                        convocatoria les incluiremos una copia de su contrato para que puedan revisarlo y consultarnos
                                        cualquier duda antes de la cita de la firma. En ese momento, nos deberán aportar además, para
                                        acompañar al contrato al presentarlo para el visado, según copia de la normativa que les
                                        incluiremos en el correo:
                                    </p>

                                    <ul>
                                        <li>Fotocopia del/os DNI/S del/os comprador/es.</li>
                                        <li>Certificado del Registro de la Propiedad que acredite que no posee ningún inmueble de su propiedad en todo el territorio Nacional.</li>
                                        <li>Fotocopia de la declaración de renta correspondiente a 2022 (presentada en 2023). </li>
                                        <li>Fotocopia de justificante de inscripción en el registro de demandantes de viviendas protegidas o de la solicitud de inscripción registrada en el IBAVI.</li>
                                    </ul>

                                    <p>
                                        Agradecemos estén, por favor, atentos a sus correos, a través de los que les iremos pasando la información, para agilizar el proceso en la medida de lo posible.
                                    </p>
                                </EntryItem>

                                <div className="entry entry-date-section col-12 mb-3"><span>DICIEMBRE 2023</span></div>

                                <EntryItem title="COMIENZO DE LAS OBRAS DE EXCAVACIÓN"
                                    date="12 Diciembre 2023">
                                    <p>
                                        Nos complace comunicar a los adjudicatarios de las viviendas de la Promoción, que en el día de hoy se dan comienzo los trabajos de excavación para las obras de construcción de nuestra promoción de 39 viviendas de VPO.
                                        En próximas fechas, una vez tengamos por parte de la Consellería d’Habitatge i Arquitectura la preceptiva autorización para percibir cantidades a cuenta de los adjudicatarios, les daremos fecha y hora a cada uno de ellos para la firma del definitivo contrato de compraventa.
                                    </p>
                                </EntryItem>

                                <div className="entry entry-date-section col-12 mb-3"><span>SEPTIEMBRE 2023</span></div>

                                <EntryItem title="ENTREGA POR PARTE DEL AYUNTAMIENTO DE CALVIÁ DE LA LICENCIA DE OBRAS"
                                    date="6 Septiembre 2023">
                                    <p>
                                        Nos complace comunicar a los adjudicatarios de las viviendas de la Promoción que en el día de hoy nos ha sido entregada en el Ayuntamiento de Calviá la Licencia de Obras preceptiva para la construcción. A partir de este momento podremos poner en marcha los procesos faltantes necesarios para comenzar la construcción de las viviendas que estimamos sea dentro de este año.
                                    </p>
                                </EntryItem>

                                <div className="entry entry-date-section col-12 mb-3"><span>JUNIO 2023</span></div>

                                <EntryItem title="NUEVA COMUNICACIÓN DE TOTALIDAD DE VIVIENDAS ADJUDICADAS"
                                    date="27 Junio 2023">
                                    <p>
                                        Se comunica a los demandantes de vivienda de la promoción que en el día de hoy se ha adjudicado la última de las viviendas que quedaban disponibles por desistimientos de adjudicatarios.
                                    </p>
                                    <p>
                                        En los próximos días, los siguientes demandantes de vivienda que componen la lista, recibirán vía correo electrónico una notificación en la que se les comunicará que pasan a la reserva para posibles desistimientos de adjudicatarios en el futuro.
                                    </p>
                                </EntryItem>

                                <EntryItem title="FINALIZACIÓN 9ª CONVOCATORIA DEL 23 DE MAYO 2023 Y RESULTADO"
                                    date="8 Junio 2023">
                                    <table className="table table-borderless w-auto entry-table">
                                        <tr>
                                            <th>Convocados hasta la fecha</th>
                                            <td className="text-end">400</td>
                                        </tr>
                                        <tr>
                                            <th>Presentados hasta la fecha</th>
                                            <td className="text-end">89</td>
                                        </tr>
                                        <tr>
                                            <th>Preadjudicados hasta la fecha</th>
                                            <td className="text-end">36</td>
                                        </tr>
                                    </table>
                                </EntryItem>

                                <div className="entry entry-date-section col-12 mb-3"><span>MAYO 2023</span></div>

                                <EntryItem title="ENVÍO 9ª CONVOCATORIA PRESENTACIÓN DOCUMENTACIÓN HASTA EL 2 DE JUNIO DE 2023 INCLUSIVE"
                                    date="23 Mayo 2023">
                                    <table className="table table-borderless w-auto entry-table">
                                        <tr>
                                            <th>Convocados</th>
                                            <td className="text-end">40 (Hasta Nro. Orden 400)</td>
                                        </tr>
                                    </table>
                                </EntryItem>

                                <EntryItem title="COMUNICACIÓN DE DESISTIMIENTOS Y VIVIENDAS DISPONIBLES"
                                    date="22 Mayo 2023">
                                    <p>
                                        Se comunica a los demandantes de vivienda de la promoción que se han producido 3 desistimientos entre personas con viviendas preadjudicadas, por lo que se lanzará una nueva convocatoria para adjudicar estas 3 viviendas, todas ellas de 1 dormitorio.
                                    </p>
                                </EntryItem>

                                <EntryItem title="COMUNICACIÓN DE TOTALIDAD DE VIVIENDAS ADJUDICADAS"
                                    date="16 Mayo 2023">
                                    <p>
                                        Se comunica a los demandantes de vivienda de la promoción que el pasado 15 de mayo quedaron adjudicadas la totalidad de las viviendas disponibles.
                                    </p>
                                </EntryItem>

                                <EntryItem title="FINALIZACIÓN 8ª CONVOCATORIA DEL 28 DE ABRIL 2023 Y RESULTADO"
                                    date="15 Mayo 2023">
                                    <table className="table table-borderless w-auto entry-table">
                                        <tr>
                                            <th>Convocados hasta la fecha</th>
                                            <td className="text-end">360</td>
                                        </tr>
                                        <tr>
                                            <th>Presentados hasta la fecha</th>
                                            <td className="text-end">82</td>
                                        </tr>
                                        <tr>
                                            <th>Preadjudicados hasta la fecha</th>
                                            <td className="text-end">36</td>
                                        </tr>
                                    </table>
                                </EntryItem>

                                <div className="entry entry-date-section col-12 mb-3"><span>ABRIL 2023</span></div>

                                <EntryItem title="ENVÍO 8ª CONVOCATORIA PRESENTACIÓN DOCUMENTACIÓN HASTA EL 12 DE MAYO DE 2023 INCLUSIVE"
                                    date="28 Abril 2023">
                                    <table className="table table-borderless w-auto entry-table">
                                        <tr>
                                            <th>Convocados</th>
                                            <td className="text-end">24 (Hasta Nro. Orden 360)</td>
                                        </tr>
                                    </table>
                                </EntryItem>

                                <EntryItem title="FINALIZACIÓN 7ª CONVOCATORIA DEL 13 DE ABRIL 2023 Y RESULTADO"
                                    date="24 Abril 2023">
                                    <table className="table table-borderless w-auto entry-table">
                                        <tr>
                                            <th>Convocados hasta la fecha</th>
                                            <td className="text-end">336</td>
                                        </tr>
                                        <tr>
                                            <th>Presentados hasta la fecha</th>
                                            <td className="text-end">79</td>
                                        </tr>
                                        <tr>
                                            <th>Preadjudicados hasta la fecha</th>
                                            <td className="text-end">34</td>
                                        </tr>
                                    </table>
                                </EntryItem>

                                <EntryItem title="ENVÍO 7ª CONVOCATORIA"
                                    subtitle="PRESENTACIÓN DOCUMENTACIÓN HASTA EL 24 DE ABRIL DE 2023 INCLUSIVE"
                                    date="13 Abril 2023">
                                    <table className="table table-borderless w-auto entry-table">
                                        <tr>
                                            <th>Convocados</th>
                                            <td className="text-end">50 (Hasta Nro. Orden 336)</td>
                                        </tr>
                                    </table>
                                </EntryItem>

                                <EntryItem title="FINALIZACIÓN 6ª CONVOCATORIA DEL 22 DE MARZO 2023 Y RESULTADO"
                                    date="3 Abril 2023">
                                    <table className="table table-borderless w-auto entry-table">
                                        <tr>
                                            <th>Convocados hasta la fecha</th>
                                            <td className="text-end">286</td>
                                        </tr>
                                        <tr>
                                            <th>Presentados hasta la fecha</th>
                                            <td className="text-end">68</td>
                                        </tr>
                                        <tr>
                                            <th>Preadjudicados hasta la fecha</th>
                                            <td className="text-end">26</td>
                                        </tr>
                                    </table>
                                </EntryItem>

                                <div className="entry entry-date-section col-12 mb-3"><span>MARZO 2023</span></div>

                                <EntryItem title="ENVÍO 6ª CONVOCATORIA"
                                    subtitle="PRESENTACIÓN DOCUMENTACIÓN HASTA EL 3 DE ABRIL DE 2023 INCLUSIVE"
                                    date="22 Marzo 2023">
                                    <table className="table table-borderless w-auto entry-table">
                                        <tr>
                                            <th>Convocados</th>
                                            <td className="text-end">50 (Hasta Nro. Orden 286)</td>
                                        </tr>
                                    </table>
                                </EntryItem>

                                <EntryItem title="INFORMACIÓN MEMORIA DE CALIDADES"
                                    date="22 Marzo 2023">
                                    <p>Puede ya consultar la memoria de calidades de la promoción en su apartado de la
                                        página de inicio.</p>
                                </EntryItem>

                                <EntryItem title="FINALIZACIÓN 5ª CONVOCATORIA DEL 7 DE MARZO 2023 Y RESULTADO"
                                    date="17 Marzo 2023">
                                    <table className="table table-borderless w-auto entry-table">
                                        <tr>
                                            <th>Convocados hasta la fecha</th>
                                            <td className="text-end">236</td>
                                        </tr>
                                        <tr>
                                            <th>Presentados hasta la fecha</th>
                                            <td className="text-end">56</td>
                                        </tr>
                                        <tr>
                                            <th>Preadjudicados hasta la fecha</th>
                                            <td className="text-end">25</td>
                                        </tr>
                                    </table>
                                </EntryItem>

                                <EntryItem title="ENVÍO 5ª CONVOCATORIA"
                                    subtitle="PRESENTACIÓN DOCUMENTACIÓN HASTA EL 17 DE MARZO DE 2023 INCLUSIVE"
                                    date="7 Marzo 2023">

                                    <table className="table table-borderless w-auto entry-table">
                                        <tr>
                                            <th>Convocados</th>
                                            <td className="text-end">50</td>
                                        </tr>
                                    </table>
                                </EntryItem>

                                <EntryItem title="FINALIZACIÓN 4ª CONVOCATORIA DEL 22 DE FEBRERO 2023 Y RESULTADO"
                                    date="6 Marzo 2023">

                                    <table className="table table-borderless w-auto entry-table">
                                        <tr>
                                            <th>Convocados hasta la fecha</th>
                                            <td className="text-end">186</td>
                                        </tr>
                                        <tr>
                                            <th>Presentados hasta la fecha</th>
                                            <td className="text-end">42</td>
                                        </tr>
                                        <tr>
                                            <th>Preadjudicados hasta la fecha</th>
                                            <td className="text-end">21</td>
                                        </tr>
                                    </table>
                                </EntryItem>

                                <div className="entry entry-date-section col-12 mb-3"><span>FEBRERO 2023</span></div>

                                <EntryItem title="ENVÍO 4ª CONVOCATORIA"
                                    subtitle="PRESENTACIÓN DOCUMENTACIÓN HASTA EL 6 DE MARZO DE 2023 INCLUSIVE"
                                    date="22 Febrero 2023">

                                    <table className="table table-borderless w-auto entry-table">
                                        <tr>
                                            <th>Convocados</th>
                                            <td className="text-end">50</td>
                                        </tr>
                                    </table>
                                </EntryItem>

                                <EntryItem title="FINALIZACIÓN 3ª CONVOCATORIA DEL 6 FEBRERO 2023 Y RESULTADO"
                                    date="20 Febrero 2023">

                                    <table className="table table-borderless w-auto entry-table">
                                        <tr>
                                            <th>Convocados hasta la fecha</th>
                                            <td className="text-end">136</td>
                                        </tr>
                                        <tr>
                                            <th>Presentados hasta la fecha</th>
                                            <td className="text-end">34</td>
                                        </tr>
                                        <tr>
                                            <th>Preadjudicados hasta la fecha</th>
                                            <td className="text-end">18</td>
                                        </tr>
                                    </table>
                                </EntryItem>

                                <EntryItem title="VIVIENDAS DE 2 Y 3 DORMITORIOS, AGOTADAS"
                                    date="07 Febrero 2023">

                                    <p>Como resultado de las adjudicaciones realizadas en la segunda convocatoria, se
                                        han agotado las viviendas de 2 y 3 dormitorios, quedando disponibles únicamente
                                        de 1 dormitorio.</p>
                                </EntryItem>

                                <EntryItem title="ENVÍO 3ª CONVOCATORIA"
                                    subtitle="PRESENTACIÓN DOCUMENTACIÓN HASTA EL 20 DE FEBRERO DE 2023 INCLUSIVE"
                                    date="06 Febrero 2023">

                                    <table className="table table-borderless w-auto entry-table">
                                        <tr>
                                            <th>Convocados</th>
                                            <td className="text-end">50</td>
                                        </tr>
                                    </table>
                                </EntryItem>

                                <EntryItem title="FINALIZACIÓN 2ª CONVOCATORIA DEL 27 ENERO 2023 Y RESULTADO"
                                    date="06 Febrero 2023">

                                    <table className="table table-borderless w-auto entry-table">
                                        <tr>
                                            <th>Convocados hasta la fecha</th>
                                            <td className="text-end">86</td>
                                        </tr>
                                        <tr>
                                            <th>Presentados hasta la fecha</th>
                                            <td className="text-end">27</td>
                                        </tr>
                                        <tr>
                                            <th>Preadjudicados hasta la fecha</th>
                                            <td className="text-end">14</td>
                                        </tr>
                                    </table>
                                </EntryItem>

                                <div className="entry entry-date-section col-12 mb-3"><span>ENERO 2023</span></div>

                                <EntryItem title="ENVIO 2ª CONVOCATORIA"
                                    subtitle="PRESENTACIÓN DOCUMENTACIÓN HASTA EL 6 DE FEBRERO DE 2023"
                                    date="27 Enero 2023">

                                    <table className="table table-borderless w-auto entry-table">
                                        <tr>
                                            <th>Convocados</th>
                                            <td className="text-end">50</td>
                                        </tr>
                                    </table>
                                </EntryItem>

                                <EntryItem title="RESULTADOS 1ª CONVOCATORIA"
                                    subtitle="FINALIZACIÓN 1ª CONVOCATORIA Y RESULTADOS"
                                    date="27 Enero 2023">
                                    <table className="table table-borderless w-auto entry-table">
                                        <tr>
                                            <th>Convocados hasta la fecha</th>
                                            <td className="text-end">36</td>
                                        </tr>
                                        <tr>
                                            <th>Presentados hasta la fecha</th>
                                            <td className="text-end">11</td>
                                        </tr>
                                        <tr>
                                            <th>Preadjudicados hasta la fecha</th>
                                            <td className="text-end">7</td>
                                        </tr>
                                    </table>
                                </EntryItem>

                                <EntryItem title="SE REANUDA EL PROCESO DE ADJUDICACIÓN"
                                    date="12 Enero 2023">
                                    <p>Ya se han convocado a los primeros 36 interesados para que presenten la
                                        documentación preceptiva para el proceso de adjudicación.</p>
                                </EntryItem>

                                <div className="entry entry-date-section col-12 mb-3"><span>DICIEMBRE 2022</span></div>

                                <EntryItem title="PLANOS DE LAS VIVIENDAS"
                                    date="29 Diciembre 2022">
                                    <p>El próximo 15 de enero colocaremos en la página web de la promoción los
                                        planos de cada una de las viviendas que la componen, con sus
                                        aparcamientos vinculados y precios, para que puedan elegir entre 1, 2 ó
                                        3 dormitorios cuando reciban su comunicación. La preferencia para elegir
                                        dependerá, exclusivamente, del orden de antigüedad en el listado del
                                        IBAVI.</p>
                                </EntryItem>

                                <EntryItem title="REINICIO DE PROCESO DE ADJUDICACIÓN"
                                    date="29 Diciembre 2022">
                                    <p>En enero estaremos ya en condiciones de reiniciar el proceso de
                                        adjudicación de las viviendas de nuestra promoción, iremos avisando por
                                        orden de antigüedad del listado facilitado por el IBAVI, por favor,
                                        respeten los plazos señalados en las distintas comunicaciones para no
                                        perder sus derechos.</p>
                                </EntryItem>

                                <EntryItem title="LICENCIA DE OBRAS"
                                    date="22 Diciembre 2022">
                                    <p>Ya se ha solicitado la licencia de obra al Ayuntamiento de Calviá.</p>
                                </EntryItem>

                                <EntryItem title="REANUDACIÓN PROCESO DE ADJUDICACIÓN"
                                    date="09 Diciembre 2022">
                                    <p>Retomando nuestros anteriores contactos con usted, le comunicamos que la
                                        Direcció General d’ Habitatge i Arquitectura del Govern Balear...</p>
                                    <a href="novedades/4" className="more-link">Ver más...</a>
                                </EntryItem>

                                <div className="entry entry-date-section col-12 mb-3"><span>ABRIL 2022</span></div>

                                <EntryItem title="¡ADVERTENCIA IMPORTANTE!"
                                    date="8 Abril 2022">
                                    <p>Para evitar posibles engaños o contactos fraudulentos que puedan
                                        perjudicar a los interesados en el proceso de adjudicación,...</p>
                                    <a href="novedades/3" className="more-link">Ver más...</a>
                                </EntryItem>

                                {canView &&
                                    <EntryItem title="FINALIZACIÓN DEL PROCESO DE RESPUESTA"
                                        date="15 Abril 2022">
                                        <p>El 15 de Abril de 2022 ha concluido el proceso de respuesta a los
                                            correos recibidos, en el caso que usted no haya recibido el correo
                                            electrónico....</p>
                                        <a href="novedades/2" className="more-link">Ver más...</a>
                                    </EntryItem>
                                }

                                <div className="entry entry-date-section col-12 mb-3"><span>MARZO 2022</span></div>

                                <EntryItem title="¡INICIA EL PROCESO DE ADJUDICACIÓN!"
                                    date="16 Marzo 2022">
                                    <p>Proceso de adjudicación para compra de viviendas de Protección Pública en
                                        Cabo Juan García, 1 de Magaluf - Calviá.</p>
                                    <a href="novedades/1" className="more-link">Ver más...</a>
                                </EntryItem>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default News