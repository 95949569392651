import {Carousel, Image} from "react-bootstrap";

const Home = () => {
    return (
        <>
            <div className="position-relative bg-black">
                <Carousel>
                    <Carousel.Item>
                        <Image src="img/01_fachada.jpg" className="w-100 opacity-75" />
                    </Carousel.Item>
                    <Carousel.Item>
                        <Image src="img/02_Planta.jpg" className="w-100 opacity-75" />
                    </Carousel.Item>
                    <Carousel.Item>
                        <Image src="img/03_lateral.jpg" className="w-100 opacity-75" />
                    </Carousel.Item>
                    <Carousel.Item>
                        <Image src="img/main2.jpg" className="w-100 opacity-75" />
                    </Carousel.Item>
                </Carousel>
                <div className="position-absolute position-absolute top-50 start-50 translate-middle">
                    <br></br><br></br><br></br><br></br><br></br>
                    <h1 className="text-white text-center">
                        <br></br>
                        <strong>39 VPP MAGALUF</strong>
                    </h1>
                    <h4 className="text-white text-center">
                        Promoción de 39 Viviendas de Protección Pública <br></br> Cabo Juan García, 1 Magaluf - Calvià
                    </h4>
                </div>
            </div>
        </>
    );
}

export default Home;