import {Breadcrumb, Col, Container, Image, Row} from 'react-bootstrap'
import {faCalendarDay, faUser} from '@fortawesome/free-solid-svg-icons'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'

const News3 = () => {
    return (
        <>
            <Container fluid className="bg-light py-4">
                <Row className="justify-content-md-between align-items-center">
                    <Col xs="auto">
                        <h1 className="text-black">
                            <strong>
                               AVISO IMPORTANTE
                            </strong>
                        </h1>
                        <h2 className="h5">
                            Comunicaciones seguras
                        </h2>
                        <span>
                            <FontAwesomeIcon icon={faCalendarDay} />
                            <span className="px-2 border-end">
                                09 Abril 2022
                            </span>
                        </span>
                        <span>
                            <FontAwesomeIcon icon={faUser} />
                            <span className="px-2">
                                PH Carreras
                            </span>
                        </span>
                    </Col>
                    <Col xs="auto">
                        <Breadcrumb>
                            <Breadcrumb.Item href="/">Inicio</Breadcrumb.Item>
                            <Breadcrumb.Item href="/novedades">Novedades</Breadcrumb.Item>
                            <Breadcrumb.Item active>Aviso importante</Breadcrumb.Item>
                        </Breadcrumb>
                    </Col>
                </Row>
            </Container>
            <Container fluid>
                <Image src="/img/news/3.png" className="w-100 p-5" />
                <p>
                <strong> MUY IMPORTANTE:</strong> <br></br>Para evitar posibles engaños o contactos fraudulentos que puedan perjudicar a los interesados en el proceso de adjudicación, les informamos de que <strong>nadie</strong>, excepto los componentes de nuestra Organización, habitualmente Ana María Vilchez Salom y/o Santos Pérez Solís, se dirigirá a ustedes ni les solicitarán documentos ni datos personales, en relación con esta Promoción. <br></br>En caso de la menor duda y por su seguridad, diríjanse al correo 39vppmagaluf@phcarreras.com, reenviando el correo dudoso recibido. Lo comprobaremos y certificaremos su veracidad o falsedad.
                </p>
                
            </Container>
        </>
    )
}

export default News3