import {Breadcrumb, Col, Container, Image, Row} from 'react-bootstrap'
import {faCalendarDay, faUser} from '@fortawesome/free-solid-svg-icons'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'

const News1 = () => {
    return (
        <>
            <Container fluid className="bg-light py-4">
                <Row className="justify-content-md-between align-items-center">
                    <Col xs="auto">
                        <h1 className="text-black">
                            <strong>
                                INICIA EL PROCESO DE ADJUDICACIÓN
                            </strong>
                        </h1>
                        <h2 className="h5">
                            39 VPP Avda S'Olivera 62 (Magaluf)
                        </h2>
                        <span>
                            <FontAwesomeIcon icon={faCalendarDay} />
                            <span className="px-2 border-end">
                                16 Marzo 2022
                            </span>
                        </span>
                        <span>
                            <FontAwesomeIcon icon={faUser} />
                            <span className="px-2">
                                PH Carreras
                            </span>
                        </span>
                    </Col>
                    <Col xs="auto">
                        <Breadcrumb>
                            <Breadcrumb.Item href="/">Inicio</Breadcrumb.Item>
                            <Breadcrumb.Item href="/novedades">Novedades</Breadcrumb.Item>
                            <Breadcrumb.Item active>Inicia el proceso de adjudicación</Breadcrumb.Item>
                        </Breadcrumb>
                    </Col>
                </Row>
            </Container>
            <Container fluid>
                <Image src="/img/news/1.png" className="w-100 p-5" />
                <p>
                    Proceso de adjudicación para compra de viviendas de Protección Pública en Av. S'Olivera, 62 de Magaluf - Calviá.
                </p>
                <p>
                    <strong>
                        Promueve, construye y adjudica: PAVIMENTOS Y HORMIGONES CARRERAS, S.A.
                    </strong>
                </p>
                <p>
                    Condiciones para ser inscrito como preadjudicatario:
                    <ul>
                        <li>
                            Formar parte de la relación de demandantes de viviendas de VPO en compra en el Municipio de Calviá facilitado por IBAVI el 04.03.2022
                        </li>
                        <li>
                            Haber respondido positivamente antes del 15.04.2022 al correo electrónico enviado por la empresa promotora PAVIMENTOS Y HORMIGONES CARRERAS, S.A.
                        </li>
                    </ul>
                </p>
                <p>
                    Condiciones para mantenerse como preadjudicatario durante el proceso de adjudicación:
                    <ul>
                        <li>
                            Responder en los plazos que se vayan fijando a los correos electrónicos que vaya enviando la empresa promotora PH CARRERAS, S.A.
                        </li>
                        <li>
                            Cumplir con los requisitos jurídicos y económicos que se irán comunicando en dichos correos electrónicos.
                        </li>
                    </ul>
                </p>
            </Container>
        </>
    )
}

export default News1