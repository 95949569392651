import { useState } from "react";
import { Breadcrumb, Button, Col, Container, Row } from "react-bootstrap";
import Appartment1Modal from "../../../components/modal/Appartment1Modal";

const Appartment1 = () => {

    const [modalShow, setModalShow] = useState(false)

    return (
        <>
            <Container fluid className="bg-light pt-5 pb-lg-4 pb-5 modal-apartment">
                <Row className="justify-content-md-between align-items-center">
                    <Col xs="auto">
                        <h1 className="text-black h3">
                            <strong>VIVIENDAS DE 1 HABITACIÓN</strong>
                        </h1>
                        <span className="border-primary border-end border-2 px-2">1 habitación</span>
                        <span className="border-primary border-end border-2 px-2">1 baño</span>
                        <span className="px-2">1 aparcamiento vinculado</span>
                    </Col>
                    <Col xs="auto">
                        <Breadcrumb>
                            <Breadcrumb.Item href="/">Inicio</Breadcrumb.Item>
                            <Breadcrumb.Item href="/viviendas">Viviendas</Breadcrumb.Item>
                            <Breadcrumb.Item active>Vivienda</Breadcrumb.Item>
                        </Breadcrumb>
                    </Col>
                </Row>
            </Container>
            <Container fluid className="px-0 position-relative bg-apartments">
                <Button className="px-5 py-3 text-white fw-bold position-absolute border-white rounded-0 start-50 translate-middle-x" onClick={() => setModalShow(true)}>VER PLANOS</Button>
            </Container>
            <Container fluid className="py-5 bg-primary text-white text-center">
                <h2>
                    <strong>
                        VIVIENDAS DE 1 HABITACIÓN
                    </strong>
                </h2>
                <p>
                    Apartamento de 1 habitación y 1 baño. Esta vivienda dispone también de 1 aparcamiento vinculado.
                </p>
            </Container>
            <Appartment1Modal
                show={modalShow}
                onHide={() => setModalShow(false)}
            />
        </>
    )
};

export default Appartment1;