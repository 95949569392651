import { Modal, Button, Container, Col, Row } from "react-bootstrap"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";

const Appartment1Modal = (props) => {
    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >

            <Container fluid className="px-5 py-4 apartmentModalList">
                <div className="mb-5">
                    <h2 className="mb-2 h3 text-black fw-bold">LISTADO PLANOS VIVIENDAS DE 1 HABITACIÓN</h2>
                    <h3 className="h4 fw-light">Pulse sobre la vivienda deseada para ver los planos</h3>
                </div>
                <div>
                    <div>
                        <div className="mb-5">
                            <h2 className="mb-3 h3 text-black fw-bold">EDIFICIO A-1</h2>
                            <div>
                                <Row className="mb-4 justify-content-md-between">
                                    <Col xs={12} sm={"auto"} className="border-appartment border-2 border-ligth-dark pe-4 mb-4 mb-lg-0">
                                        <h3 className="h5 mb-3 fw-bold text-dark">PLANTA BAJA (PB)</h3>
                                        <p className="text-primary h5 mb-3"><a href='/pdf/1d/A1 PB-1.pdf' target="_blank" rel="noreferrer">- Vivienda A1 PB-1 (vendido)</a></p>
                                        <p className="text-primary h5 mb-3"><a href='/pdf/1d/A1 PB-2.pdf' target="_blank" rel="noreferrer">- Vivienda A1 PB-2 (vendido)</a></p>
                                        <p className="text-primary h5 mb-3"><a href='/pdf/1d/A1 PB-4.pdf' target="_blank" rel="noreferrer">- Vivienda A1 PB-4 (vendido)</a></p>
                                        <p className="text-primary h5 mb-3"><a href='/pdf/1d/A1 PB-5.pdf' target="_blank" rel="noreferrer">- Vivienda A1 PB-5 (vendido)</a></p>
                                    </Col>
                                    <Col xs={12} md={"auto"} className="border-appartment border-2 border-ligth-dark px-lg-4 mb-4 mb-lg-0">
                                        <h3 className="h5 mb-3 fw-bold text-dark">PRIMERA PLANTA (P1)</h3>
                                        <p className="text-primary h5 mb-3"><a href='/pdf/1d/A1 P1-1.pdf' target="_blank" rel="noreferrer">- Vivienda A1 P1-1 (vendido)</a></p>
                                        <p className="text-primary h5 mb-3"><a href='/pdf/1d/A1 P1-2.pdf' target="_blank" rel="noreferrer">- Vivienda A1 P1-2 (vendido)</a></p>
                                        <p className="text-primary h5 mb-3"><a href='/pdf/1d/A1 P1-4.pdf' target="_blank" rel="noreferrer">- Vivienda A1 P1-4 (vendido)</a></p>
                                        <p className="text-primary h5 mb-3"><a href='/pdf/1d/A1 P1-5.pdf' target="_blank" rel="noreferrer">- Vivienda A1 P1-5 (vendido)</a></p>
                                    </Col>
                                    <Col xs={12} md={"auto"} className="px-lg-4">
                                        <h3 className="h5 mb-3 fw-bold text-dark">SEGUNDA PLANTA (P2)</h3>
                                        <p className="text-primary h5 mb-3"><a href='/pdf/1d/A1 P2-1.pdf' target="_blank" rel="noreferrer">- Vivienda A1 P2-1 (vendido)</a></p>
                                        <p className="text-primary h5 mb-3"><a href='/pdf/1d/A1 P2-2.pdf' target="_blank" rel="noreferrer">- Vivienda A1 P2-2 (vendido)</a></p>
                                        <p className="text-primary h5 mb-3"><a href='/pdf/1d/A1 P2-4.pdf' target="_blank" rel="noreferrer">- Vivienda A1 P2-4 (vendido)</a></p>
                                        <p className="text-primary h5 mb-3"><a href='/pdf/1d/A1 P2-5.pdf' target="_blank" rel="noreferrer">- Vivienda A1 P2-5 (vendido)</a></p>
                                    </Col>
                                </Row>
                            </div>
                        </div>
                        <div>
                            <h2 className="mb-3 h3 text-black fw-bold">EDIFICIO A-2</h2>
                            <div>
                                <Row className="mb-4 justify-content-md-between">
                                    <Col xs={12} md={"auto"} className="border-appartment border-2 border-ligth-dark pe-lg-4 mb-4 mb-lg-0">
                                        <h3 className="h5 mb-3 fw-bold text-dark">PLANTA BAJA (PB)</h3>
                                        <p className="text-primary h5 mb-3"><a href='/pdf/1d/A2 PB-2.pdf' target="_blank" rel="noreferrer">- Vivienda A2 PB-2 (vendido)</a></p>
                                        <p className="text-primary h5 mb-3"><a href='/pdf/1d/A2 PB-3.pdf' target="_blank" rel="noreferrer">- Vivienda A2 PB-3 (vendido)</a></p>
                                        <p className="text-primary h5 mb-3"><a href='/pdf/1d/A2 PB-4.pdf' target="_blank" rel="noreferrer">- Vivienda A2 PB-4 (vendido)</a></p>
                                        <p className="text-primary h5 mb-3"><a href='/pdf/1d/A2 PB-5.pdf' target="_blank" rel="noreferrer">- Vivienda A2 PB-5 (vendido)</a></p>
                                        <p className="text-primary h5 mb-3"><a href='/pdf/1d/A2 PB-6.pdf' target="_blank" rel="noreferrer">- Vivienda A2 PB-6 (vendido)</a></p>
                                    </Col>
                                    <Col xs={12} md={"auto"} className="border-appartment border-2 border-ligth-dark px-lg-4 mb-4 mb-lg-0">
                                        <h3 className="h5 mb-3 fw-bold text-dark">PRIMERA PLANTA (P1)</h3>
                                        <p className="text-primary h5 mb-3"><a href='/pdf/1d/A2 P1-2.pdf' target="_blank" rel="noreferrer">- Vivienda A2 P1-2 (vendido)</a></p>
                                        <p className="text-primary h5 mb-3"><a href='/pdf/1d/A2 P1-3.pdf' target="_blank" rel="noreferrer">- Vivienda A2 P1-3 (vendido)</a></p>
                                        <p className="text-primary h5 mb-3"><a href='/pdf/1d/A2 P1-4.pdf' target="_blank" rel="noreferrer">- Vivienda A2 P1-4 (vendido)</a></p>
                                        <p className="text-primary h5 mb-3"><a href='/pdf/1d/A2 P1-5.pdf' target="_blank" rel="noreferrer">- Vivienda A2 P1-5 (vendido)</a></p>
                                        <p className="text-primary h5 mb-3"><a href='/pdf/1d/A2 P1-6.pdf' target="_blank" rel="noreferrer">- Vivienda A2 P1-6 (vendido)</a></p>
                                    </Col>
                                    <Col xs={12} md={"auto"} className="px-lg-4">
                                        <h3 className="h5 mb-3 fw-bold text-dark">SEGUNDA PLANTA (P2)</h3>
                                        <p className="text-primary h5 mb-3"><a href='/pdf/1d/A2 P2-2.pdf' target="_blank" rel="noreferrer">- Vivienda A2 P2-2 (vendido)</a></p>
                                        <p className="text-primary h5 mb-3"><a href='/pdf/1d/A2 P2-3.pdf' target="_blank" rel="noreferrer">- Vivienda A2 P2-3 (vendido)</a></p>
                                        <p className="text-primary h5 mb-3"><a href='/pdf/1d/A2 P2-4.pdf' target="_blank" rel="noreferrer">- Vivienda A2 P2-4 (vendido)</a></p>
                                        <p className="text-primary h5 mb-3"><a href='/pdf/1d/A2 P2-5.pdf' target="_blank" rel="noreferrer">- Vivienda A2 P2-5 (vendido)</a></p>
                                        <p className="text-primary h5 mb-3"><a href='/pdf/1d/A2 P2-6.pdf' target="_blank" rel="noreferrer">- Vivienda A2 P2-6 (vendido)</a></p>
                                    </Col>
                                </Row>
                            </div>
                        </div>
                        <div className="d-flex justify-content-center">
                            <Button className="bg-danger rounded-0 py-1 px-5 text-white" onClick={props.onHide}>Cerrar</Button>
                        </div>
                    </div>
                </div>
                <FontAwesomeIcon icon={faXmark} size={'2x'} className="text-danger position-absolute top-0 end-0 pe-3 pt-2 cursor-pointer" onClick={props.onHide} />
            </Container>
        </Modal>
    )
}

export default Appartment1Modal