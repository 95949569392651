import {Col, Container, Image, Row} from "react-bootstrap";

const Footer = () => {
    return (
        <footer>
            <Container fluid>
                <Row className="py-4">
                    <Col className="align-self-center">
                        <Image src="/img/logo.png" />
                    </Col>
                    <Col md="auto">
                        <h5 className="text-primary">
                            <strong>PROYECTO</strong>
                        </h5>
                        <ul className="text-primary">
                            <li>
                                <a href="/">Inicio</a>
                            </li>
                            <li>
                                <a target="_blank" href="/pdf/report.pdf">Memorias calidades</a>
                            </li>
                            <li>
                                <a href="/viviendas">Viviendas</a>
                            </li>
                            <li>
                                <a href="/novedades">Novedades</a>
                            </li>
                            <li>
                                <a href="/novedades/3">Advertencia<br></br> importante</a>
                            </li>
                        </ul>
                    </Col>
                    <Col md="auto">
                        <h5 className="text-primary">
                            <strong>CONTACTO</strong>
                        </h5>
                        <ul className="text-primary remove-list-style p-0">
                            <li>
                            <b>PH Carreras, S.A.</b> <br></br>c/ Baltasar Moya, 30<br></br>07600 Es Pilari - Palma 
                            </li>
                            <li>
                                Telf. 971 266 344
                            </li>
                            <li>
                                Mail. 39vppmagaluf@phcarreras.com
                            </li>
                        </ul>
                    </Col>
                </Row>
            </Container>
            <div className="bg-primary text-white d-flex flex-column flex-sm-row justify-content-center py-2 text-center">
                <span>
                    ©2022 CARRERAS
                </span>
                <span className="mx-2">
                    -
                </span>
                39 VPP MAGALUF
                
            </div>
        </footer>
    );
}

export default Footer;