import {DateTime} from "luxon";
import {Breadcrumb, Col, Container, Image, Row} from 'react-bootstrap'
import {faCalendarDay, faUser} from '@fortawesome/free-solid-svg-icons'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'

const News4 = () => {
    return (
        <>
            <Container fluid className="bg-light py-4">
                <Row className="justify-content-md-between align-items-center">
                    <Col xs="auto">
                        <h1 className="text-black">
                            <strong>
                                REANUDACIÓN PROCESO DE ADJUDICACIÓN
                            </strong>
                        </h1>
                        <h2 className="h5">
                            39 VPP Cabo Juan García, 1 (Magaluf)
                        </h2>
                        <span>
                            <FontAwesomeIcon icon={faCalendarDay} />
                            <span className="px-2 border-end">
                                09 Diciembre 2022
                            </span>
                        </span>
                        <span>
                            <FontAwesomeIcon icon={faUser} />
                            <span className="px-2">
                                PH Carreras
                            </span>
                        </span>
                    </Col>
                    <Col xs="auto">
                        <Breadcrumb>
                            <Breadcrumb.Item href="/">Inicio</Breadcrumb.Item>
                            <Breadcrumb.Item href="/novedades">Novedades</Breadcrumb.Item>
                            <Breadcrumb.Item active>Reanudación proceso de adjudicación</Breadcrumb.Item>
                        </Breadcrumb>
                    </Col>
                </Row>
            </Container>
            <Container fluid>
                <div className={'text-center'}>
                    <Image src="/img/news/18.png" className="p-5" />
                </div>
                <p>
                    Retomando nuestros anteriores contactos con usted, le comunicamos que la Direcció General d’ Habitatge i Arquitectura del Govern Balear nos acaba de hacer entrega de la <strong>CALIFICACIÓN PROVISIONAL DE VIVIENDAS PROTEGIDAS EN RÉGIMEN GENERAL DE VENTA PERMANENTE</strong> para nuestro proyecto de promoción privada de 39 VPP EN CABO JUAN GARCÍA, 1 DE MAGALUF (T.M. CALVIA-MALLORCA).
                </p>
                <p>
                    Para su información, adjuntamos dicho documento en el que se detallan los precios y superficies de cada una de las viviendas con su aparcamiento anexo, el cual procedemos del mismo modo a publicar en la Página Web de la Promoción.
                </p>
                <p>
                    Asimismo, comunicamos que vamos a proseguir con el procedimiento de adjudicación de las mencionadas viviendas por riguroso orden de lista de los interesados actualmente inscritos en la relación de demandantes facilitada por el IBAVI el 4 de marzo de 2022.
                </p>
                <p>
                    Para ello, en los próximos días, nos iremos poniendo en contacto con los demandantes de vivienda, exclusivamente vía email, y respetando estrictamente el mencionado orden de la relación del IBAVI. En esta comunicación les indicaremos los pasos a seguir y los requisitos para acceder a la adjudicación de una de las viviendas de nuestro proyecto, hasta que estas se agoten, en cuyo momento comunicaremos al resto de interesados esta circunstancia.
                </p>
                <p>
                    Les informamos, una vez más, de que cualquier consulta o comunicación que deseen realizar relacionada con el proceso de esta promoción, deben hacerla, hasta que se les comunique lo contrario, exclusivamente via correo electrónico, pues es la única forma en que podremos atenderles convenientemente, como deseamos. Por favor, absténganse de comunicarse telefónicamente, pues sería imposible poderles atender debido al elevado número de demandantes.
                </p>
                <p>
                    Finalizado el procedimiento de adjudicación, por haberse adjudicado todas las viviendas del proyecto, se comunicará a quienes no hayan sido contactados del listado del IBAVI y por tanto no hayan resultado adjudicatarios hasta ese momento, que quedan en situación de reserva temporal para el caso en que pueda existir alguna baja entre los adjudicatarios durante el proceso de la formalización de la entrega de la vivienda, en cuyo caso iríamos avisando a los siguientes de la lista.
                </p>
                
            </Container>
        </>
    )
}

export default News4