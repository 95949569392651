import { useState } from "react";
import { Breadcrumb, Button, Col, Container, Row } from "react-bootstrap";
import Appartment2Modal from "../../../components/modal/Appartment2Modal";

const Appartment2 = () => {

    const [modalShow, setModalShow] = useState(false)

    return (
        <>
            <Container fluid className="bg-light pt-5 pb-lg-4 pb-5">
                <Row className="justify-content-md-between align-items-center">
                    <Col xs="auto">
                        <h1 className="text-black h3">
                            <strong>VIVIENDAS DE 2 HABITACIONES</strong>
                        </h1>
                        <span className="border-secondary border-end px-1">2 habitaciones</span>
                        <span className="border-secondary border-end px-1">1 baño</span>
                        <span className="px-1">1 aparcamiento vinculado</span>
                    </Col>
                    <Col xs="auto">
                        <Breadcrumb>
                            <Breadcrumb.Item href="/">Inicio</Breadcrumb.Item>
                            <Breadcrumb.Item href="/viviendas">Viviendas</Breadcrumb.Item>
                            <Breadcrumb.Item active>Vivienda</Breadcrumb.Item>
                        </Breadcrumb>
                    </Col>
                </Row>
            </Container>
            <Container fluid className="px-0 position-relative bg-apartments">
                <Button className="px-5 py-3 text-white fw-bold position-absolute border-white rounded-0 start-50 translate-middle-x" onClick={() => setModalShow(true)}>VER PLANOS</Button>
            </Container>
            <Container fluid className="py-5 bg-primary text-white text-center">
                <h2>
                    <strong>
                        VIVIENDAS DE 2 HABITACIONES
                    </strong>
                </h2>
                <p>
                    Vivienda de 2 habitaciones y 1 baño. Esta vivienda dispone también de 1 aparcamiento vinculado.
                </p>
            </Container>
            <Appartment2Modal
                show={modalShow}
                onHide={() => setModalShow(false)}
            />
        </>
    )
};

export default Appartment2;