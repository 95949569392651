import {Col, Container, Image, Row} from "react-bootstrap"
import ApartmentCard from "../../components/ApartmentCard"

const Apartments = () => {
    return (
        <div>
            <Image src="img/main.jpg" className="w-100" />
            <Container fluid className="bg-light text-center py-3">
                <h1 className="text-primary">
                    <strong>39 VPP MAGALUF</strong>
                </h1>
                <h2 className="h5 text-black">
                    Promoción 39 VPP Cabo Juan García, 1 Magaluf - Calvià
                </h2>
            </Container>
            <Container fluid className="text-center">
            <br></br>
                <div className="w-75 mx-auto">
                    <div className="divider">
                        <h3 className="text-primary">
                            Tipologías de viviendas
                        </h3>
                    </div>
                    <br></br>
                </div>
                <Row>
                    <Col md={4} className="mb-4 mb-md-0">
                        <ApartmentCard img="img/apartments/blueprint1.png" title="Una habitación" href="viviendas/1">
                            <span className="border-secondary border-end pe-1 text-black">1 habitación</span>
                            <span className="border-secondary px-1 text-black">1 baño</span>
                            <p className="text-black">1 aparcamiento vinculado</p>
                        </ApartmentCard>
                    </Col>
                    <Col md={4} className="mb-4 mb-md-0">
                        <ApartmentCard img="img/apartments/blueprint2.png" title="Dos habitaciones" href="viviendas/2">
                            <span className="border-secondary border-end pe-1 text-black">2 habitaciones</span>
                            <span className="px-1 text-black">1 baño</span>
                            <p className="text-black">1 aparcamiento vinculado</p>
                        </ApartmentCard>
                    </Col>
                    <Col md={4} className="mb-4 mb-md-0">
                        <ApartmentCard img="img/apartments/blueprint3.png" title=" Tres habitaciones" href="viviendas/3">
                            <span className="border-secondary border-end pe-1 text-black">3 habitaciones</span>
                            <span className="px-1 text-black">1 o 2 baños</span>
                            <p className="text-black">1 aparcamiento vinculado</p>
                        </ApartmentCard>
                    </Col>
                </Row>
            </Container>
            <br></br><br></br>
        </div>

    )
}

export default Apartments