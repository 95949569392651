import {Container, Nav, Navbar} from "react-bootstrap"

import TopbarLink from "./TopbarLink";

const Topbar = () => {

    return (
        <Navbar className="p-0" expand="lg">
            <Container fluid className="d-flex justify-content-md-between">
                <div>
                    <Navbar.Brand href="/" className="d-flex p-0">
                        <img alt="logo carreras"
                            src="/img/logo.png"
                            className="d-inline-block align-top my-3" />

                        <div className="border-end align-self-stretch d-inline mx-4" />

                        <span className="text-primary align-self-center">
                            <strong className="text-wrap">
                                39 VPP MAGALUF
                            </strong>
                        </span>
                    </Navbar.Brand>
                </div>
                <Navbar.Toggle aria-controls="navbarScroll" />
                <Navbar.Collapse className="align-items-center justify-content-end">
                    <Nav>
                        <TopbarLink path="/">
                            <strong>INICIO</strong>
                            <p>El proyecto</p>
                        </TopbarLink>
                        <TopbarLink path="/pdf/report.pdf" target="_blank">
                            <strong>Calidades</strong>
                            <p>Memoria de calidades</p>
                        </TopbarLink>
                        <TopbarLink path="/viviendas">
                            <strong>VIVIENDAS</strong>
                            <p>Calidades, tipos...</p>
                        </TopbarLink>
                        <TopbarLink path="/novedades">
                            <strong>NOVEDADES</strong>
                            <p>Noticias sobre promoción y construcción</p>
                        </TopbarLink>

                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    )
}

export default Topbar