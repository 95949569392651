import {DateTime} from "luxon";
import {Breadcrumb, Col, Container, Image, Row} from 'react-bootstrap'
import {faCalendarDay, faUser} from '@fortawesome/free-solid-svg-icons'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'

const News2 = () => {

    // go to home if day is not april 15th or later

    if (DateTime.local().year === 2022 && DateTime.local().month <= 4 && DateTime.local().day < 15) {
        window.location.href = "/";
    }

    return (
        <>
            <Container fluid className="bg-light py-4">
                <Row className="justify-content-md-between align-items-center">
                    <Col xs="auto">
                        <h1 className="text-black">
                            <strong>
                            FINALIZACION DEL PROCESO DE RESPUESTA
                            </strong>
                        </h1>
                        <h2 className="h5">
                            39 VPP Avda S'Olivera 62 (Magaluf)
                        </h2>
                        <span>
                            <FontAwesomeIcon icon={faCalendarDay} />
                            <span className="px-2 border-end">
                                15 Abril 2022
                            </span>
                        </span>
                        <span>
                            <FontAwesomeIcon icon={faUser} />
                            <span className="px-2">
                                PH Carreras
                            </span>
                        </span>
                    </Col>
                    <Col xs="auto">
                        <Breadcrumb>
                            <Breadcrumb.Item href="/">Inicio</Breadcrumb.Item>
                            <Breadcrumb.Item href="/novedades">Novedades</Breadcrumb.Item>
                            <Breadcrumb.Item active>Termina el plazo de inscripción</Breadcrumb.Item>
                        </Breadcrumb>
                    </Col>
                </Row>
            </Container>
            <Container fluid>
                <Image src="/img/news/2.png" className="w-100 p-5" />
                <p>
                El 15 de Abril de 2022 ha concluido el proceso de respuesta a los correos recibidos. En caso de que usted no haya recibido el correo electrónico o no haya respondido y quisiera ser incluido en el proceso de adjudicación, envíenos urgentemente un correo electrónico a 39vppmagaluf@phcarreras.com manifestando su deseo de ser incorporado.
                </p>
            </Container>
        </>
    )
}

export default News2