import {faEye} from "@fortawesome/free-solid-svg-icons";
import {Card} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

const ApartmentCard = ({img, title, children, href}) => {
    return (
        <a href={href}>
            <Card className="shadow-sm">
                <div className="position-relative">
                    <Card.Img variant="top" src={img} />
                    <FontAwesomeIcon icon={faEye} className=" position-absolute top-0 end-0 text-white m-3" size="3x" />
                </div>
                <Card.Body>
                    <Card.Title className="text-start text-black">
                        <strong>
                            {title}
                        </strong>
                    </Card.Title>
                    <Card.Text className="text-start">
                        {children}
                    </Card.Text>
                </Card.Body>
            </Card>
        </a>
    );
}

export default ApartmentCard;