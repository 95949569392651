import {BrowserRouter, Route, Routes} from 'react-router-dom';

import Apartments from './pages/apartments/Apartments';
import Appartment1 from './pages/apartments/1/Appartment1';
import Appartment2 from './pages/apartments/2/Appartment2';
import Appartment3 from './pages/apartments/3/Appartment3';
import Home from './pages/home/Home';
import Main from './pages/main/Main';
import News from './pages/news/News';
import News1 from './pages/news/1/News1';
import News2 from './pages/news/2/News2';
import News3 from './pages/news/3/News3';
import News4 from './pages/news/4/News4';


function App() {
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<Main />} >
                    <Route index element={<Home />} />
                    <Route path="/viviendas">
                        <Route index element={<Apartments />} />
                        <Route path="1" element={<Appartment1 />} />
                        <Route path="2" element={<Appartment2 />} />
                        <Route path="3" element={<Appartment3 />} />
                    </Route>
                    <Route path="/novedades">
                        <Route index element={<News />} />
                        <Route path="1" element={<News1 />} />
                        <Route path="2" element={<News2 />} />
                        <Route path="3" element={<News3 />} />
                        <Route path="4" element={<News4 />} />
                    </Route>
                </Route>
            </Routes>
        </BrowserRouter>
    );
}

export default App;
